.ant-modal-footer {
	border-top: 0px transparent !important;
}

.feedbackButton {
	height: 29px;
	width: 93px;
	border-radius: 5px 5px 0 0;
	background-color: #18376E;
	border: 1px solid #18376E;
	color: #FFFFFF;
	font-family: Gotham;
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	text-align: center;
	padding: 0px;
	position: fixed;
	right: -32px;
	bottom: 50%;
	transform: rotate(-90deg);
	z-index: 2;
}

.feedbackFormHeader {
	/*height: 25px;*/
	/*width: 382px;*/
	color: #3C3C3C;
	font-family: Gotham;
	font-size: 25px;
	font-weight: 500;
	line-height: 30px;
	margin-bottom: 10px;
}

.feedbackFormText {
	/*height: 40px;*/
	/*width: 484px;*/
	color: #AFAFAF;
	font-family: Gotham;
	font-size: 16px;
	font-weight: 300;
	line-height: 20px;
	margin-bottom: 10px;
}

.feedbackFormStarContainer {
	/*display: inline-flex;*/
	/*justify-content: space-between;*/
	/*width: 50%;*/
	color: #FFEB00 !important;
	font-size: 36px !important;
}

.feedbackFormInput {
	border-radius: 0px !important;
	border: 0px transparent !important;
	border-bottom: 1px solid #D8D8D8 !important;
	padding-left: 0px !important;
	/*color: #AFAFAF;*/
	font-family: Gotham !important;
	font-size: 16px !important;
	font-weight: 300 !important;
	line-height: 19px !important;
	margin-bottom: 10px !important;
	margin-top: 10px !important;
}

.feedbackFormButtonContainer {
	width: 100%;
	text-align: right;
}

.feedbackSubmitButton {
	height: 40px;
	width: 167px;
	border-radius: 5px;
	margin-right: 4px;
	margin-bottom: 10px;
	/*background-color: #009DFF;*/
}

.feedbackFormModalWrapper > .ant-modal > .ant-modal-content {
	border-radius: 10px !important;
	/*background: red;*/
}

.ant-rate-star-full > .ant-rate-star-second > .feedbackStarIcon path {
	fill: #FFEB00;
}

.ant-rate-star-full > .ant-rate-star-second > .feedbackStarIcon svg {
	fill: #FFEB00;
}

.ant-rate-star-full > .ant-rate-star-second > .feedbackStarIcon g {
	fill: #FFEB00;
}

.ant-rate-star-full g {
	fill: #FFEB00;
}

/*.feedbackStarIcon path {
	fill: #FFEB00 !important;
}

.feedbackStarIcon svg {
	fill: #FFEB00 !important;
}

.feedbackStarIcon g {
	fill: #FFEB00 !important;
}*/

@media (max-width: 767px) {
	.feedbackButton {
		right: 22px;
		bottom: 0px;
		transform: rotate(0deg);
	}
}