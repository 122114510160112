.footerContainer {
	height: 108px;
	width: 100vw;
	background-color: #3D3D3D;
	color: white;
	z-index: 1;
}

.footerRoom {
	max-width: 1136px;
	width: 100%;
	margin: auto;
}

.poweredBy {
	display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
	font-family: Gotham;
	font-size: 12px;
	font-weight: 400;
	line-height: 30px;
	white-space: nowrap;
	cursor: pointer;
}

.followUs {
	font-family: Gotham;
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 10px;
}

.footerSocialIcon:hover path {
	fill: lightGray;
}

.footerSocialIcon path {
	fill: white;
}

.footerSocialIcon svg {
	height: 33px;
	width: 33px;
}

.footerSocialIcon {
	height: 33px;
	width: 33px;
	cursor: pointer;
}

.footerSocialRoom {
	max-width: 205;
}

@media (max-width: 767px) {
	.footerContainer {
		height: 100%;
		padding: 20px !important;
	}

	.poweredBy {
		justify-content: flex-start;
	}

	.footerSocialIcon svg {
		height: 49px;
		width: 49px;
	}

	.footerSocialIcon {
		height: 49px;
		width: 49px;
	}

	.footerSocialRoom {
		max-width: 100%;
	}
}