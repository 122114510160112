/*.header .ant-menu-horizontal {
  line-height: 65px;
}
*/
.headerContainer {
	background-color: white;
	height: 105px;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 2;
	padding-left: 0px;
	padding-right: 0px;
	/*z-index: 9999;*/
}

.headerTopLayer {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 56px;
	width: 100vw;
	background-color: #18376E;
}

.headerRoom {
	max-width: 1136px;
  width: 100%;
  margin: auto;
  padding-top: 31px;
  z-index: 2;
}

.headerLogoButton {
	height: 64px;
	width: 264px;
	border-radius: 10px;
	background-color: #FFFFFF;
	box-shadow: 0 1px 6px 0 rgba(0,0,0,0.15);
	border: 0px;
}

.headerCol {
	display: flex;
	justify-content: flex-end;
	/*align-items: center;*/
}

.headerIcon path {
	fill: white;
}

.headerIcon svg {
	height: 23px;
	width: 23px;
}

.headerIcon {
	height: 23px;
	width: 23px;
}

.headerIcon.marginLeft {
	margin-left: 5px;
}

.headerExplore {
  display: flex;
	justify-content: flex-end; 
  align-items: center;
  color: #FFFFFF;
  font-family: Gotham;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: right;
}

.headerExploreDrawer {

}

.tmcMenuDrawer {
	height: 87px;
	border-bottom: 1px solid #F3F3F3;
	display: flex;
	justify-content: center;
	align-items: center;
}

.menuDrawerItem {
  min-height: 66px !important;
  border-bottom: 1px solid #F3F3F3;
  display: flex;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-left: 36px !important;
  color: #404040;
  font-family: Gotham;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin: 0px !important;
}

.menuDrawerItem.withIcon {
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 0px transparent;
}

.subMenuDrawerItem {
  min-height: 66px !important;
  border-bottom: 1px solid #F3F3F3;
  display: flex;
  padding-top: 13px !important;
  padding-bottom: 0px !important;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-left: 0px !important;
  color: #404040;
  font-family: Gotham;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin: 0px !important;
}

.subMenuDrawerItem > .ant-menu-submenu-title {
	padding-left: 36px !important;
	/*background-color: green;*/
}

.subMenuDrawerIcon path {
	fill: white;
}

.subMenuDrawerIcon {
	height: 32px;
	margin-right: 12px;
}

.ant-menu-inline .ant-menu-submenu-title {
	margin-bottom: 13px;
}

.ant-menu-submenu .ant-menu-sub > .menuDrawerItem {
	padding-left: 36px !important;
	background-color: #009DFF;
	/*border-bottom: 0px transparent;*/
	color: white;
}

.ant-menu-inline {
	border-right: 0px transparent !important;
}

.ant-menu-submenu > .ant-menu-item-selected {
	font-weight: 500 !important;
  color: white !important;
  border-right: 0px transparent !important;
  background: #009DFF !important;
}

.ant-menu-submenu > .ant-menu-item:active, .ant-menu-submenu > .ant-menu-submenu-title:active {
	font-weight: 500 !important;
  color: white !important;
  border-right: 0px transparent !important;
  background: #009DFF !important;
}

.ant-menu-submenu > .ant-menu-item::after {
	font-weight: 500 !important;
  color: white !important;
  border-right: 0px transparent !important;
  background: #009DFF !important;
}

.ant-menu-sub > .ant-menu-item-selected {
  /*font-weight: 500 !important;*/
  font-weight: bold !important;
  /*color: #009DFF !important;*/
  border-right: 0px transparent !important;
  /*background: white !important;*/
}

 .ant-menu-light > .ant-menu-item-selected {
  font-weight: 500 !important;
  /*font-weight: bold !important;*/
  color: #009DFF !important;
  border-right: 0px transparent !important;
  background: white !important;
}

.ant-menu-submenu-selected {
  font-weight: 500 !important;
  color: #009DFF !important;
  border-right: 0px transparent !important;
  background: white !important;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: white !important;
  font-weight: 500 !important;
  color: #009DFF !important;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 0px transparent !important;
  background: white !important;
}

@media (max-width: 767px) {
	.headerCol {
		display: none;
	}

	.headerTopLayer {
		display: none;
	}

	.headerRoom {
		background-color: #18376E;
		height: 55px;
		width: 100vw;
		margin: 0;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 0px;
		display: flex;
		align-items: center;
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;
	}

	.headerContainer {
		height: 55px;
		position: unset;
	}
}