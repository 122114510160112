.homeBackground {
  background:
    /* linear-gradient(
      rgba(24, 144, 255, 0.75),
      rgba(200, 225, 255, 0.45)
    ), */
    url('/img/Landing Background@2x.webp') no-repeat;
  background-size: cover;
  background-position: center;
}

.homeTopGradientLayer {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 244px;
  width: 100vw;
  transform: scaleY(-1);
  // background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 100%);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
}

.homeBottomGradientLayer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 350px;
  width: 100vw;
  // background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.68) 100%);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
}

.mainLayoutContent {
  margin: 0 16px;
  margin-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainLayoutComponentContainer {
  padding-top: 25px;
  padding-left: 17px;
  min-height: 360px;
  height: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .mainLayoutContent {
    /*max-height: calc(100vh - 213px);*/
    margin-top: 105px;
    margin-left: 275px;
  }

  .mainLayoutComponentContainer {
    /*max-height: calc(100vh - 213px);*/
  }
}

@media (max-width: 767px) {
  .homeTopGradientLayer {
    height: 257px;
  }

  .homeBottomGradientLayer {
    height: 0px;
  }

  .mainLayoutContent {
    margin-right: 16px;
    margin-top: 0px;
    margin-left: 16px;
  }

  .mainLayoutComponentContainer {
    padding-right: 17px;
  }
}

.authsider {
  background:
    /* linear-gradient(
      rgba(24, 144, 255, 0.75),
      rgba(200, 225, 255, 0.45)
    ), */
    url('/img/hero-bg.jpg') no-repeat;
  background-size: cover;
}
.sider .logo {
  font-size: 52px;
  text-shadow: 5px 5px 10px #00000099;
  margin-top: 10px;
}

.sider .logosmall {
  font-size: 26px;
  text-shadow: 5px 5px 10px #00000099;
  margin-top: 10px;
}

.sider .logo p {
  margin: 0;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
  margin: 0 auto;
  max-width: 1136px;
  width: 100%;
}

.ant-drawer-body {
  padding: 0px;
}

.ant-layout-header {
  line-height: unset;
}

.ant-layout-sider {
  background: transparent;
}

.ant-layout {
  background-color: white;
}
