/* common components */

@font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-light-webfont.woff2');
    font-weight: 300;
    font-style: normal;
 }

@font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-book-webfont.woff2');
    font-weight: 400;
    font-style: normal;
 }

 @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-medium-webfont.woff2');
    font-weight: 500;
    font-style: normal;
 }

 @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-bold-webfont.woff2');
    font-weight: bold;
    font-style: normal;
 }

.not-found,
.sorry {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 40px;
}

/* override Ant Design with the following syntax: .yourClass.antdClass */

.error.ant-alert {
    margin-bottom: 20px;
}

.form-button.ant-btn {
    width: 100%;
}

/* disable scroll bounce */

html, body {
    height: 100%;
    overflow: hidden;
}

.app {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
}

/* nav and main content layout */

.main {
    margin-left: 200px !important;
    background: #fafafa !important;
    height: 100vh !important;
}

/*.header .ant-menu-horizontal {
  line-height: 65px;
}
*/
.headerContainer {
	background-color: white;
	height: 105px;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 2;
	padding-left: 0px;
	padding-right: 0px;
	/*z-index: 9999;*/
}

.headerTopLayer {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 56px;
	width: 100vw;
	background-color: #18376E;
}

.headerRoom {
	max-width: 1136px;
  width: 100%;
  margin: auto;
  padding-top: 31px;
  z-index: 2;
}

.headerLogoButton {
	height: 64px;
	width: 264px;
	border-radius: 10px;
	background-color: #FFFFFF;
	box-shadow: 0 1px 6px 0 rgba(0,0,0,0.15);
	border: 0px;
}

.headerCol {
	display: flex;
	justify-content: flex-end;
	/*align-items: center;*/
}

.headerIcon path {
	fill: white;
}

.headerIcon svg {
	height: 23px;
	width: 23px;
}

.headerIcon {
	height: 23px;
	width: 23px;
}

.headerIcon.marginLeft {
	margin-left: 5px;
}

.headerExplore {
  display: flex;
	justify-content: flex-end; 
  align-items: center;
  color: #FFFFFF;
  font-family: Gotham;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: right;
}

.headerExploreDrawer {

}

.tmcMenuDrawer {
	height: 87px;
	border-bottom: 1px solid #F3F3F3;
	display: flex;
	justify-content: center;
	align-items: center;
}

.menuDrawerItem {
  min-height: 66px !important;
  border-bottom: 1px solid #F3F3F3;
  display: flex;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-left: 36px !important;
  color: #404040;
  font-family: Gotham;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin: 0px !important;
}

.menuDrawerItem.withIcon {
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 0px transparent;
}

.subMenuDrawerItem {
  min-height: 66px !important;
  border-bottom: 1px solid #F3F3F3;
  display: flex;
  padding-top: 13px !important;
  padding-bottom: 0px !important;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-left: 0px !important;
  color: #404040;
  font-family: Gotham;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin: 0px !important;
}

.subMenuDrawerItem > .ant-menu-submenu-title {
	padding-left: 36px !important;
	/*background-color: green;*/
}

.subMenuDrawerIcon path {
	fill: white;
}

.subMenuDrawerIcon {
	height: 32px;
	margin-right: 12px;
}

.ant-menu-inline .ant-menu-submenu-title {
	margin-bottom: 13px;
}

.ant-menu-submenu .ant-menu-sub > .menuDrawerItem {
	padding-left: 36px !important;
	background-color: #009DFF;
	/*border-bottom: 0px transparent;*/
	color: white;
}

.ant-menu-inline {
	border-right: 0px transparent !important;
}

.ant-menu-submenu > .ant-menu-item-selected {
	font-weight: 500 !important;
  color: white !important;
  border-right: 0px transparent !important;
  background: #009DFF !important;
}

.ant-menu-submenu > .ant-menu-item:active, .ant-menu-submenu > .ant-menu-submenu-title:active {
	font-weight: 500 !important;
  color: white !important;
  border-right: 0px transparent !important;
  background: #009DFF !important;
}

.ant-menu-submenu > .ant-menu-item::after {
	font-weight: 500 !important;
  color: white !important;
  border-right: 0px transparent !important;
  background: #009DFF !important;
}

.ant-menu-sub > .ant-menu-item-selected {
  /*font-weight: 500 !important;*/
  font-weight: bold !important;
  /*color: #009DFF !important;*/
  border-right: 0px transparent !important;
  /*background: white !important;*/
}

 .ant-menu-light > .ant-menu-item-selected {
  font-weight: 500 !important;
  /*font-weight: bold !important;*/
  color: #009DFF !important;
  border-right: 0px transparent !important;
  background: white !important;
}

.ant-menu-submenu-selected {
  font-weight: 500 !important;
  color: #009DFF !important;
  border-right: 0px transparent !important;
  background: white !important;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: white !important;
  font-weight: 500 !important;
  color: #009DFF !important;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 0px transparent !important;
  background: white !important;
}

@media (max-width: 767px) {
	.headerCol {
		display: none;
	}

	.headerTopLayer {
		display: none;
	}

	.headerRoom {
		background-color: #18376E;
		height: 55px;
		width: 100vw;
		margin: 0;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 0px;
		display: flex;
		align-items: center;
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;
	}

	.headerContainer {
		height: 55px;
		position: unset;
	}
}

.exploreButton {
	height: 52px;
	/*max-width: 196px;*/
	width: 100%;
	border-radius: 6px;
	font-size: 18px;
	/*background-color: #009DFF;*/
}

.exploreDrawerButton {
	height: 52px;
	/*max-width: 196px;*/
	width: 100%;
	margin-left: 11px;
	margin-right: 11px;
	border-radius: 10px;
	font-size: 18px;
	/*background-color: #009DFF;*/
}

.travelSelectChevron path {
	fill: black;
}

.travelSelectChevron svg {
	height: 20px;
	width: 20px;
}

.travelSelectChevron {
/*	height: 20px;
	width: 20px;*/
	margin-top: -5px;
  transform: rotate(90deg);
}

.travelIcon path {
	fill: #009DFF;
	/*background-color: red;*/
}

.travelIcon svg {
	height: 30px;
	width: 30px;
}

.travelIcon {
	height: 30px;
	width: 30px;
	display: flex;
}

.travelDivider {
	border-right: 1px solid #E4E4E4;
}

.travelSelect .ant-select-selection {
	height: 100%;
	font-size: 16px;
	border: 0px transparent;
	outline: none;
	box-shadow: none;
}

.travelSelect .ant-select-selection__rendered {
	height: 100%;
	display: flex;
	align-items: center;
	font-weight: 500;
	outline: none;
}

.travelSelect .ant-select-selection__placeholder {
	font-weight: 300;
	outline: none;
}

.travelSelect {
	outline: none;
}

.travelContainer {
	max-height: 40px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-right: 18px;
	padding-left: 18px;
}

.travelCol {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.flexEnd {
	justify-content: flex-end;
}

.exploreContainer {
	height: 64px;
	width: 842px;
	border-radius: 10px;
	background-color: #FFFFFF;
	box-shadow: 0 0 3px 2px rgba(0,0,0,0.08);
	padding: 6px;
	font-family: Gotham;
	font-weight: 500;
}

.exploreDrawerContainer {
	height: 64px;
	width: 842px;
	border-radius: 10px;
	background-color: #FFFFFF;
	/*box-shadow: 0 0 3px 2px rgba(0,0,0,0.08);*/
	padding: 6px;
	font-family: Gotham;
	font-weight: 500;
}

.tripleDotLine {
	display: none;
  position: absolute;
  transform: rotate(90deg);
  top: -7px;
  left: 22px;
  font-size: 10px;
	color: #009DFF;
}

@media (max-width: 767px) {
	.exploreContainer {
		height: 138px;
		width: 335px;
		padding: 0px;
	}

	.exploreDrawerContainer {
		margin-top: 25px;
		height: 138px;
		padding: 0px;
		width: 100%;
	}

	.exploreButton {
		border-radius: 10px;
	}

	.travelDivider {
		border-right: 0px transparent;
	}

	.travelCol {
		height: 50%;
	}

	.tripleDotLine {
    display: inline;
	}
}
.homeBackground {
  background: url("/img/Landing Background@2x.webp") no-repeat;
  background-size: cover;
  background-position: center; }

.homeTopGradientLayer {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 244px;
  width: 100vw;
  transform: scaleY(-1);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); }

.homeBottomGradientLayer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 350px;
  width: 100vw;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%); }

.mainLayoutContent {
  margin: 0 16px;
  margin-right: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.mainLayoutComponentContainer {
  padding-top: 25px;
  padding-left: 17px;
  min-height: 360px;
  height: 100%;
  width: 100%; }

@media (min-width: 768px) {
  .mainLayoutContent {
    /*max-height: calc(100vh - 213px);*/
    margin-top: 105px;
    margin-left: 275px; }
  .mainLayoutComponentContainer {
    /*max-height: calc(100vh - 213px);*/ } }

@media (max-width: 767px) {
  .homeTopGradientLayer {
    height: 257px; }
  .homeBottomGradientLayer {
    height: 0px; }
  .mainLayoutContent {
    margin-right: 16px;
    margin-top: 0px;
    margin-left: 16px; }
  .mainLayoutComponentContainer {
    padding-right: 17px; } }

.authsider {
  background: url("/img/hero-bg.jpg") no-repeat;
  background-size: cover; }

.sider .logo {
  font-size: 52px;
  text-shadow: 5px 5px 10px #00000099;
  margin-top: 10px; }

.sider .logosmall {
  font-size: 26px;
  text-shadow: 5px 5px 10px #00000099;
  margin-top: 10px; }

.sider .logo p {
  margin: 0; }

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
  margin: 0 auto;
  max-width: 1136px;
  width: 100%; }

.ant-drawer-body {
  padding: 0px; }

.ant-layout-header {
  line-height: unset; }

.ant-layout-sider {
  background: transparent; }

.ant-layout {
  background-color: white; }

.nav .sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
}

.nav .options {
    text-align: center;
    margin-bottom: 15px;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.session_error {
  background:
    /* linear-gradient(
      rgba(24, 144, 255, 0.75),
      rgba(200, 225, 255, 0.45)
    ), */
    url('/img/Landing Background@2x.webp') no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
}

.top_gradient {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  transform: scaleY(-1);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 100%);
}

.bottom_gradient {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.68) 100%);
}

.error_text {
  /*width: 111px;*/
  color: #FFFFFF;
  font-family: Gotham;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin: 100px;
}

.footerContainer {
	height: 108px;
	width: 100vw;
	background-color: #3D3D3D;
	color: white;
	z-index: 1;
}

.footerRoom {
	max-width: 1136px;
	width: 100%;
	margin: auto;
}

.poweredBy {
	display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
	font-family: Gotham;
	font-size: 12px;
	font-weight: 400;
	line-height: 30px;
	white-space: nowrap;
	cursor: pointer;
}

.followUs {
	font-family: Gotham;
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 10px;
}

.footerSocialIcon:hover path {
	fill: lightGray;
}

.footerSocialIcon path {
	fill: white;
}

.footerSocialIcon svg {
	height: 33px;
	width: 33px;
}

.footerSocialIcon {
	height: 33px;
	width: 33px;
	cursor: pointer;
}

.footerSocialRoom {
	max-width: 205;
}

@media (max-width: 767px) {
	.footerContainer {
		height: 100%;
		padding: 20px !important;
	}

	.poweredBy {
		justify-content: flex-start;
	}

	.footerSocialIcon svg {
		height: 49px;
		width: 49px;
	}

	.footerSocialIcon {
		height: 49px;
		width: 49px;
	}

	.footerSocialRoom {
		max-width: 100%;
	}
}
.siderContainer {
	margin-top: 20px;
	max-width: 275px !important;
  width: 275px !important;
  flex-basis: 275px !important;
  position: fixed !important;
  top: 100px !important;
}

.siderMainButton {
  padding-top: 15px;
	height: 50px;
	width: 263px;
	border: 1px solid #F3F3F3 !important;
	border-radius: 10px;
	background-color: #FFFFFF;
	box-shadow: 0 2px 6px 0 rgba(0,0,0,0.15);
	color: #404040;
	font-family: Gotham;
	font-size: 16px;
	font-weight: 300;
	line-height: normal;
	outline: none;
	display: flex;
	justify-content: flex-start;
	padding-left: 21px !important;
}

.siderMainButton.haveChildren {
	position: absolute;
	top: 0px;
}

.siderSelectedButton {
	font-weight: 500 !important;
	color: #009DFF !important;
}

.siderSelectedChildren {
	font-weight: bold !important;
}

.siderChildrenContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-weight: 300;
	margin-top: 15px;
	margin-bottom: 15px;
}

.siderChildrenContainer:focus, .siderChildrenContainer:hover, .siderChildrenContainer:active {
	font-weight: bold !important;
}

.ant-collapse-header {
	font-family: Gotham;
	font-size: 16px;
	font-weight: 300;
	line-height: normal !important;
	padding-left: 21px !important;
	height: 50px !important;
	width: 263px !important;
	border-radius: 10px !important;
	box-shadow: 0 2px 6px 0 rgba(0,0,0,0.15);
	background: white !important;
	display: flex;
	align-items: center;
	/*margin-bottom: 10px !important;*/
}

.ant-collapse-header:hover, .ant-collapse-header:focus {
	font-weight: 500;
	color: #009DFF !important;
}

.siderSelectedButton > .ant-collapse-header {
	font-weight: 500;
	color: #009DFF !important;
}

.ant-collapse {
	width: 263px !important;
	background: #009DFF;
	border: 0px transparent !important;
	border-radius: 10px;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	/*margin-bottom: 10px;*/
}

.ant-collapse-content {
	background: #009DFF;
	color: white;
	border: 0px transparent;
	border-bottom-right-radius: 10px !important;
	border-bottom-left-radius: 10px !important;
	/*padding-top: 10px;*/
}

.ant-collapse-content-box {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
  left: auto;
  left: initial;
  right: 16px;
}

.ant-collapse > .ant-collapse-item:last-child {
	border-radius: 10px !important;
}

.siderMainButton:hover, .siderMainButton:focus, .siderMainButton:active {
	font-weight: 500;
}

.siderCol {
	margin-bottom: 10px;
}

@media (max-width: 767px) {
	.siderContainer {
		display: none;
	}
}

.ant-modal-footer {
	border-top: 0px transparent !important;
}

.feedbackButton {
	height: 29px;
	width: 93px;
	border-radius: 5px 5px 0 0;
	background-color: #18376E;
	border: 1px solid #18376E;
	color: #FFFFFF;
	font-family: Gotham;
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	text-align: center;
	padding: 0px;
	position: fixed;
	right: -32px;
	bottom: 50%;
	transform: rotate(-90deg);
	z-index: 2;
}

.feedbackFormHeader {
	/*height: 25px;*/
	/*width: 382px;*/
	color: #3C3C3C;
	font-family: Gotham;
	font-size: 25px;
	font-weight: 500;
	line-height: 30px;
	margin-bottom: 10px;
}

.feedbackFormText {
	/*height: 40px;*/
	/*width: 484px;*/
	color: #AFAFAF;
	font-family: Gotham;
	font-size: 16px;
	font-weight: 300;
	line-height: 20px;
	margin-bottom: 10px;
}

.feedbackFormStarContainer {
	/*display: inline-flex;*/
	/*justify-content: space-between;*/
	/*width: 50%;*/
	color: #FFEB00 !important;
	font-size: 36px !important;
}

.feedbackFormInput {
	border-radius: 0px !important;
	border: 0px transparent !important;
	border-bottom: 1px solid #D8D8D8 !important;
	padding-left: 0px !important;
	/*color: #AFAFAF;*/
	font-family: Gotham !important;
	font-size: 16px !important;
	font-weight: 300 !important;
	line-height: 19px !important;
	margin-bottom: 10px !important;
	margin-top: 10px !important;
}

.feedbackFormButtonContainer {
	width: 100%;
	text-align: right;
}

.feedbackSubmitButton {
	height: 40px;
	width: 167px;
	border-radius: 5px;
	margin-right: 4px;
	margin-bottom: 10px;
	/*background-color: #009DFF;*/
}

.feedbackFormModalWrapper > .ant-modal > .ant-modal-content {
	border-radius: 10px !important;
	/*background: red;*/
}

.ant-rate-star-full > .ant-rate-star-second > .feedbackStarIcon path {
	fill: #FFEB00;
}

.ant-rate-star-full > .ant-rate-star-second > .feedbackStarIcon svg {
	fill: #FFEB00;
}

.ant-rate-star-full > .ant-rate-star-second > .feedbackStarIcon g {
	fill: #FFEB00;
}

.ant-rate-star-full g {
	fill: #FFEB00;
}

/*.feedbackStarIcon path {
	fill: #FFEB00 !important;
}

.feedbackStarIcon svg {
	fill: #FFEB00 !important;
}

.feedbackStarIcon g {
	fill: #FFEB00 !important;
}*/

@media (max-width: 767px) {
	.feedbackButton {
		right: 22px;
		bottom: 0px;
		transform: rotate(0deg);
	}
}
