
.exploreButton {
	height: 52px;
	/*max-width: 196px;*/
	width: 100%;
	border-radius: 6px;
	font-size: 18px;
	/*background-color: #009DFF;*/
}

.exploreDrawerButton {
	height: 52px;
	/*max-width: 196px;*/
	width: 100%;
	margin-left: 11px;
	margin-right: 11px;
	border-radius: 10px;
	font-size: 18px;
	/*background-color: #009DFF;*/
}

.travelSelectChevron path {
	fill: black;
}

.travelSelectChevron svg {
	height: 20px;
	width: 20px;
}

.travelSelectChevron {
/*	height: 20px;
	width: 20px;*/
	margin-top: -5px;
  transform: rotate(90deg);
}

.travelIcon path {
	fill: #009DFF;
	/*background-color: red;*/
}

.travelIcon svg {
	height: 30px;
	width: 30px;
}

.travelIcon {
	height: 30px;
	width: 30px;
	display: flex;
}

.travelDivider {
	border-right: 1px solid #E4E4E4;
}

.travelSelect .ant-select-selection {
	height: 100%;
	font-size: 16px;
	border: 0px transparent;
	outline: none;
	box-shadow: none;
}

.travelSelect .ant-select-selection__rendered {
	height: 100%;
	display: flex;
	align-items: center;
	font-weight: 500;
	outline: none;
}

.travelSelect .ant-select-selection__placeholder {
	font-weight: 300;
	outline: none;
}

.travelSelect {
	outline: none;
}

.travelContainer {
	max-height: 40px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-right: 18px;
	padding-left: 18px;
}

.travelCol {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.flexEnd {
	justify-content: flex-end;
}

.exploreContainer {
	height: 64px;
	width: 842px;
	border-radius: 10px;
	background-color: #FFFFFF;
	box-shadow: 0 0 3px 2px rgba(0,0,0,0.08);
	padding: 6px;
	font-family: Gotham;
	font-weight: 500;
}

.exploreDrawerContainer {
	height: 64px;
	width: 842px;
	border-radius: 10px;
	background-color: #FFFFFF;
	/*box-shadow: 0 0 3px 2px rgba(0,0,0,0.08);*/
	padding: 6px;
	font-family: Gotham;
	font-weight: 500;
}

.tripleDotLine {
	display: none;
  position: absolute;
  transform: rotate(90deg);
  top: -7px;
  left: 22px;
  font-size: 10px;
	color: #009DFF;
}

@media (max-width: 767px) {
	.exploreContainer {
		height: 138px;
		width: 335px;
		padding: 0px;
	}

	.exploreDrawerContainer {
		margin-top: 25px;
		height: 138px;
		padding: 0px;
		width: 100%;
	}

	.exploreButton {
		border-radius: 10px;
	}

	.travelDivider {
		border-right: 0px transparent;
	}

	.travelCol {
		height: 50%;
	}

	.tripleDotLine {
    display: inline;
	}
}