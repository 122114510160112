.siderContainer {
	margin-top: 20px;
	max-width: 275px !important;
  width: 275px !important;
  flex-basis: 275px !important;
  position: fixed !important;
  top: 100px !important;
}

.siderMainButton {
  padding-top: 15px;
	height: 50px;
	width: 263px;
	border: 1px solid #F3F3F3 !important;
	border-radius: 10px;
	background-color: #FFFFFF;
	box-shadow: 0 2px 6px 0 rgba(0,0,0,0.15);
	color: #404040;
	font-family: Gotham;
	font-size: 16px;
	font-weight: 300;
	line-height: normal;
	outline: none;
	display: flex;
	justify-content: flex-start;
	padding-left: 21px !important;
}

.siderMainButton.haveChildren {
	position: absolute;
	top: 0px;
}

.siderSelectedButton {
	font-weight: 500 !important;
	color: #009DFF !important;
}

.siderSelectedChildren {
	font-weight: bold !important;
}

.siderChildrenContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-weight: 300;
	margin-top: 15px;
	margin-bottom: 15px;
}

.siderChildrenContainer:focus, .siderChildrenContainer:hover, .siderChildrenContainer:active {
	font-weight: bold !important;
}

.ant-collapse-header {
	font-family: Gotham;
	font-size: 16px;
	font-weight: 300;
	line-height: normal !important;
	padding-left: 21px !important;
	height: 50px !important;
	width: 263px !important;
	border-radius: 10px !important;
	box-shadow: 0 2px 6px 0 rgba(0,0,0,0.15);
	background: white !important;
	display: flex;
	align-items: center;
	/*margin-bottom: 10px !important;*/
}

.ant-collapse-header:hover, .ant-collapse-header:focus {
	font-weight: 500;
	color: #009DFF !important;
}

.siderSelectedButton > .ant-collapse-header {
	font-weight: 500;
	color: #009DFF !important;
}

.ant-collapse {
	width: 263px !important;
	background: #009DFF;
	border: 0px transparent !important;
	border-radius: 10px;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	/*margin-bottom: 10px;*/
}

.ant-collapse-content {
	background: #009DFF;
	color: white;
	border: 0px transparent;
	border-bottom-right-radius: 10px !important;
	border-bottom-left-radius: 10px !important;
	/*padding-top: 10px;*/
}

.ant-collapse-content-box {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
  left: initial;
  right: 16px;
}

.ant-collapse > .ant-collapse-item:last-child {
	border-radius: 10px !important;
}

.siderMainButton:hover, .siderMainButton:focus, .siderMainButton:active {
	font-weight: 500;
}

.siderCol {
	margin-bottom: 10px;
}

@media (max-width: 767px) {
	.siderContainer {
		display: none;
	}
}
