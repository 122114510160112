.session_error {
  background:
    /* linear-gradient(
      rgba(24, 144, 255, 0.75),
      rgba(200, 225, 255, 0.45)
    ), */
    url('/img/Landing Background@2x.webp') no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
}

.top_gradient {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  transform: scaleY(-1);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 100%);
}

.bottom_gradient {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.68) 100%);
}

.error_text {
  /*width: 111px;*/
  color: #FFFFFF;
  font-family: Gotham;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin: 100px;
}
